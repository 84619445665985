const links = {
  instagram: {
    url: 'https://www.instagram.com/amazinggracegroomingsalon/',
    icon: ['fab', 'instagram'],
  },
  facebook: {
    url: 'https://www.facebook.com/Amazing-Grace-Grooming-Salon-218299486877320/',
    icon: ['fab', 'facebook'],
  },
}

export default links;