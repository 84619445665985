<template>
  <div id="app">
    <div class="card-container">
      <h1>Amazing Grace Grooming Salon</h1>
      <h2>Chrissy Colarusso - Professional Pet Groomer</h2>
            <div
        class="social-media-container"
      >
      <a
        v-for="(socialLink, $index) in socialLinks"
        :key="$index"
        :href="socialLink.url"
        target="_blank"
      >
        <font-awesome-icon
          :icon="socialLink.icon"
        />
      </a>
      </div>
      <div class="headshot-container">
        <img :src="`${publicPath}img/examples/image-025.jpg`"
          class="headshot"
          alt='Picture of the owner of the Amazing Grace Grooming Salon Chrissy Colarusso'
        >
        <img :src="`${publicPath}img/examples/image-000.jpg`"
          class="image"
          alt='Example work of Amazing Grace Grooming Salon'
        >
      </div>
      <div
        class="about"
      >
      <p>
        After working in both a corporate salon and various private salons for the last 9 years,
        I am so excited to have the opportunity to open my own shop, <b>Amazing Grace Grooming Salon!</b>
        I recently relocated back home to the Lehigh Valley from Myrtle Beach, SC, to open my own shop and be near our family while we raise our 4 year-old son.
      </p>

      <vueper-slides
        :bullets="false"
        fade
        autoplay
        :slide-ratio="3024/4032"
        class="images-container"
      >
        <vueper-slide
        v-for="(exampleImage, $index) in shopImages"
        :key="$index"
        :image="exampleImage"
        class="example"
      />
      </vueper-slides>
      <p>We can't wait to pamper your sweet pooches and look forward to seeing you soon!
      Please email
        <a href="mailto:chrissy@amazinggracegroomingsalon.com">chrissy@amazinggracegroomingsalon.com</a>
        or call the Salon at
        <a href="tel:6102628000">(610) 262-8000</a> for pricing of services and to book for your appointment.
        <p><b>Thank you!!</b></p>
      </div>
      <img :src="`${publicPath}img/examples/image-024.png`"
        class="contact-card"
        alt='Picture with contact details for Amazing Grace Grooming Salon'
      >
    </div>
    <vueper-slides
      :bullets="false"
      fade
      autoplay
      :slide-ratio="3024/4032"
      class="images-container"
    >
      <vueper-slide
      v-for="(exampleImage, $index) in exampleImages"
      :key="$index"
      :image="exampleImage"
      class="example"
    />
    </vueper-slides>
    <div
      class="contact-info"
    >
      <address>
        <br>Amazing Grace Grooming Salon
        <br>1631 Main Street
        <br>Northampton, PA 18067
        <br><a href="tel:6102628000">(610) 262-8000</a>
        <br>
        <a href="mailto:chrissy@amazinggracegroomingsalon.com">
            Contact Us!
            <br>
        chrissy@amazinggracegroomingsalon.com
        </a>
      </address>
      <div
        class="social-media-container"
      >
        <a
          v-for="(socialLink, $index) in socialLinks"
          :key="$index"
          :href="socialLink.url"
          target="_blank"
        >
          <font-awesome-icon
            :icon="socialLink.icon"
          />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'
import links from '@/constants/links';

export default {
  name: 'App',
  components: {
    VueperSlides,
    VueperSlide
  },
  created() {
    document.title = 'Amazing Grace Grooming Salon (610) 262-8000'
  },
  data () {
    return {
      publicPath: process.env.BASE_URL
    }
  },
  computed: {
    socialLinks() {
      return links;
    },
    exampleImages() {
      const excludedIndices = [
        0,
        24,
        25,
      ];
      const imagesArray = [];

      for (let index = 42; index >= 0; index--) {
        if (!excludedIndices.includes(index)){
          const prefix = index < 10
            ? '0'
            : '';
          imagesArray.push(`${this.publicPath}img/examples/image-0${prefix}${index}.jpg`);
        }
      }
      return imagesArray;
    },
    shopImages() {
      const excludedIndices = [
      ];
      const imagesArray = [];

      for (let index = 0; index <= 7; index++) {
        if (!excludedIndices.includes(index)){
          const prefix = index < 10
            ? '0'
            : '';
          imagesArray.push(`${this.publicPath}img/shop/image-0${prefix}${index}.jpg`);
        }
      }
      return imagesArray;
    },
  },
}
</script>
<style>
body {
  margin: 0px;
  padding:0px;
  background-color: #cc9c96;
}
</style>
<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

#app {
  text-align: center;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  color: #242627;
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  .card-container,
  .contact-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    margin-bottom: 10px;
    .headshot-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 100%;
      max-width: 600px;
      .headshot {
        padding: 0 5px 10px 0;
        height: 100%;
        width: 100%;
        max-height: 300px;
        max-width: 300px;
        object-fit:cover;
      }
      .image {
        padding-bottom: 10px;
        height: 100%;
        width: 100%;
        max-height: 300px;
        max-width: 300px;
        object-fit:cover;
      }
    }
    .contact-card {
      height: 100%;
      width: 100%;
      max-width: 600px;
      object-fit:cover;
    }
  }
  .about {
    max-width: 600px;
    padding-bottom: 20px;
    text-align: center;
  }
  .images-container {
    width: 600px;
    max-width: 100%;
    margin: auto;
    .example {
      background-size: contain;
      background-repeat: no-repeat;
    }
  }
  .social-media-container {
    margin: 0 0 20px 0;
    a {
      padding-right: 5px;
      svg {
        font-size: 24px;
      }
    }
  }
}
</style>
